<template>
  <v-container fluid style="height: 100vh;">

    <!-- PART SELECTION STAGE (STEP 2) -->
    <v-layout column>

      <v-toolbar dark color="primary">
        <v-icon @click="$router.go(-1)">fas fa-arrow-left</v-icon>
        <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="selectedBOM">
          <v-btn class="hidden-sm-and-down" flat :disabled="!buildupValid || succeeded" :loading="building" @click="buildupParts()">Buildup</v-btn>
          <v-btn class="hidden-md-and-up" icon :disabled="!buildupValid || succeeded" :loading="building" @click="buildupParts()">
            <v-icon>far fa-check-circle</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-progress-linear v-if="loadingBOMs" color="blue" indeterminate></v-progress-linear>
      <v-flex pa-2>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent=""
        >
          <v-text-field
            v-model="pts"
            label="Enter item PT #'s here for fast entry!"
            :loading="searchingWithPTs"
            :error-messages="ptErrors"
            :disabled="succeeded"
            box
            @keyup.enter="searchForItemPTs()"
          >
            <template #message="{ key, message }">
              <span :key="key" v-html="message"/>
            </template>
          </v-text-field>
        </v-form>
        <v-layout column>
          <span class="error--text" v-html="incorrectPartMessage"/>
          <span class="error--text" v-html="notFoundMessage"/>
          <t-alert :message="successMessage" type="success"/>
        </v-layout>
      </v-flex>
      <!-- LIST OF PARTS IN BOM -->
      <v-flex>
        <v-data-table
          :headers="partHeaders"
          :items="parts"
          :loading="loadingParts"
          class="elevation-1"
          hide-actions
        >
          <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
          <template v-slot:items="props">
            <tr>
              <td>
                <part-icon :name="props.item.part.type" />
              </td>
              <td>{{ props.item.part.pn }}</td>
              <td>{{ props.item.inventory.ptID }}</td>
              <td>{{ getShownQuantityToAdd(props.item) }}</td>
              <td class="px-0" v-if="!succeeded">
                <span v-if="!props.item.inventory.ptID">
                  <v-btn @click="selectPart(props.item)" color="primary" flat><v-icon class="fas fa-arrow-right"></v-icon></v-btn>
                </span>
                <span v-else>
                  <v-btn @click="selectPart(props.item)" color="warning" flat><v-icon small class="fas fa-edit"></v-icon></v-btn>
                  <v-btn @click="removeInventoryItemFromPart(props.item)" color="error" flat><v-icon small class="fas fa-trash"></v-icon></v-btn>
                </span>
              </td>
              <td class="text-xs-right"></td>
            </tr>
          </template>
          <!-- Footer -->
          <template #footer v-if="baseItem.part.pn !== ''">
            <v-container><h3>Base Item</h3></v-container>
            <tr>
              <td>
                <v-icon color="primary">fas fa-server</v-icon>
              </td>
              <td>{{ baseItem.part.pn }}</td>
              <td>{{ baseItem.item.ptID }}</td>
              <td>{{ baseItem.item.ptID ? 0 : 1 }}</td>
              <td class="px-0">
                <span v-if="!baseItem.item.id">
                  <v-btn @click="selectPart(baseItem)" color="primary" flat><v-icon class="fas fa-arrow-right"></v-icon></v-btn>
                </span>
                <span v-else>
                  <v-btn @click="selectPart(baseItem)" color="warning" flat><v-icon small class="fas fa-edit"></v-icon></v-btn>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-dialog
          v-model="dialog"
          persistent
          scrollable
          max-width="1000px"
        >
          <v-card v-if="selectedPart" class="grey lighten-5">
            <v-card-title>
              <span>Select <b>{{ selectedPart.part.pn }}</b></span>
              <v-spacer></v-spacer>

              <!-- Search Input Field -->
              <v-text-field
                v-model="inventorySearch"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-card-text>
              <v-data-table
                v-model="selected"
                :headers="inventoryHeaders"
                :items="inventory"
                :search="inventorySearch"
                :loading="loadingInventory"
                class="elevation-1"
                item-key="id"
                hide-actions
                ref="inventoryTable"
              >
                <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>

                <!-- Default Table -->
                <template v-slot:items="props">
                  <tr
                    @click="props.selected = !props.selected"
                    :key="props.item.id"
                    @click.shift="multiSelect(props)"
                    :style="userSelectStyle"
                  >
                    <td>
                      <v-checkbox
                        v-model="props.selected"
                        hide-details
                        color="info"
                        :disabled="!props.selected && selected.length >= max"
                        @click.stop="props.selected = !props.selected"
                      />
                    </td>
                    <td>{{ props.item.ptID }}</td>
                    <td><span class="my-monospaced">{{ props.item.serial_number }}</span></td>
                    <!--                  <td class="px-0"><v-btn @click="selectInventory(props.item)" color="primary">SELECT</v-btn></td>-->
                  </tr>

                  <!-- After selecting an item from the inventory close the search window and view the remaining parts in the bom list of parts -->
                </template>

                <!-- If the search returns nothing show an error message -->
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning"> Your search for "{{ inventorySearch }}" found no results</v-alert>
                </template>

              </v-data-table>
            </v-card-text>
            <v-card-actions class="pt-4">
              <v-btn color="warning" :disabled="selected.length === 0" @click="selected = []">Reset Selection</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialog = false">Cancel</v-btn>
              <v-btn color="success" :disabled="selected.length < min" @click="selectInventory()">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="showPartSelectionDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title class="light-blue">
          <h3 class="white--text">Select a part to build from</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row justify-center align-center>
              <v-flex xs6>
                <standard-autofill
                  :id="bomID"
                  :config="bomSelectConfig"
                  :items="partsWithBOMs"
                  :load="loadingPartsWithBOMs"
                  @updated="bomID = $event.value"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="error"
            @click="$router.go(-1)"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ITEM_FROM_PT_ID, ITEMS_FOR_BUILDUP } from '@/api/graphql/Constants/Inventory'
import { mapActions } from 'vuex'
import {
  CREATE_BUILDUP,
  CREATE_BUILDUP_ITEMS, GET_BOM_PARTS__FULL,
  GET_BOMS_FOR_PARTS, GET_BUILD_UPS__GRID, GET_PARTS_BOMS__FULL
} from '@/api/graphql/Constants/Assembly'
import partIcon from './part-icon'
import standard2 from '@/components/autocompletes/standard2'
import tAlert from '@/components/notifications/tAlert'
// system id, id of system they have. Dictionary definition

// have a table of parts to build up
// then have a v-select that they choose from the list of approved parts
// and then they add that part, opening up a window that searches for specific items in the database.

// PN and PT ID
// PT too?
// Part Type possiblities
// Other
// Processor
// Memory
// Storage
// then have a v-select that they choose from (which contains the list of approved parts)
export default {
  name: 'NewBuildUp',
  mixins: [],
  components: {
    'standard-autofill': standard2,
    't-alert': tAlert,
    'part-icon': partIcon
  },
  computed: {
    // this needs to return true after one item is selected because they might start from a server that
    // is missing any where between only one item and all items
    buildupValid () {
      return this.inventoryItemsToBuildUp.length > 0 && (this.baseItem.item.id ?? false)
    },

    userSelectStyle () {
      return { 'user-select': (this.shiftIsPressed ? 'none' : 'text') }
    },

    inventoryItemsToBuildUp () {
      const ids = []
      for (const part of this.parts) {
        for (const holdee of part.hold) {
          ids.push(holdee)
        }
      }
      return ids
    },

    toolbarTitle () {
      if (this.selectedBOM) {
        return `${this.selectedBOM.bom_for.pn} - ${this.selectedBOM.description}`
      } else {
        return 'Select A BOM to Buildup'
      }
    },

    selectedPtIDs () {
      const items = []
      for (const part of this.parts) {
        for (const holdee of part.hold) {
          items.push(holdee.ptID)
        }
      }
      return items
    },

    succeeded () {
      return this.successMessage !== ''
    }
  },
  watch: {
    bomID: function (value) {
      if (value) {
        const bomObject = this.partsWithBOMs.filter(b => b.id === value)[0]
        this.selectBOM(bomObject)
      }
    },

    pts: function (value) {
      if (!value) {
        this.ptErrors = ''
        this.$refs.form.resetValidation()
      }
    }
  },
  data () {
    return {
      dialog: false,
      incorrectPartMessage: '',
      successMessage: '',
      notFoundMessage: '',
      width: '10%',
      selectedBOM: undefined,
      selectedPart: undefined,
      outputBOM: undefined,
      building: false,
      loadingBOMs: false,
      boms: [],
      loadingParts: false,
      parts: [],
      selected: [],
      min: 0,
      max: 0,
      partHeaders: [
        { text: 'Type', sortable: false },
        { text: 'PN', sortable: false },
        { text: 'PT ID', sortable: false },
        { text: 'Quantity to Add', sortable: false },
        { text: 'Actions', sortable: false }
      ],
      loadingInventory: false,
      inventorySearch: '',
      inventory: [],
      // added value here to allow filtering/searching to work
      inventoryHeaders: [
        { text: 'Select', value: 'id', sortable: false },
        { text: 'PT ID', value: 'ptID', sortable: false },
        { text: 'Serial Number', value: 'serial_number', sortable: false }
      ],

      baseItem: {
        part: {
          id: 0,
          pn: ''
        },
        item: {
          ptID: '',
          id: 0
        }
      },

      // standard-autofill in dialog data
      showPartSelectionDialog: false,
      bomID: 0,
      partsWithBOMs: [],
      bomSelectConfig: {
        name: 'bomID',
        label: 'Select a part to build up'
      },
      loadingPartsWithBOMs: false,

      // pt text-field data
      valid: true,
      pts: '',
      searchingWithPTs: false,
      ptErrors: '',

      // multi select for the inventory selection table
      shiftIsPressed: false
    }
  },
  apollo: {
    partsWithBOMs: {
      query: GET_PARTS_BOMS__FULL,
      variables: { input: {} },
      skip () {
        return this.$route.params?.id ?? false
      },
      update (data) {
        data.parts_boms.forEach(b => {
          b.title = b.bom_for.pn
        })
        return data.parts_boms.filter(b => b.parts.length > 0)
      },
      watchLoading (isLoading) {
        this.loadingPartsWithBOMs = isLoading
      }
    }
  },
  methods: {
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar'
    }),

    buildupParts () {
      this.building = true
      this.$apollo.mutate({
        mutation: CREATE_BUILDUP,
        variables: {
          id: this.baseItem.item.id
        }
      }).then(async ({ data: { Create__BuildUp_BuildUp } }) => {
        const buildUpID = Create__BuildUp_BuildUp.id
        // rewrite below
        const items = []
        for (const item of this.inventoryItemsToBuildUp) {
          items.push({
            build_up_id: buildUpID,
            item_id: item.id,
            status_id: 79 // created (build up item)
          })
        }
        this.$apollo.mutate({
          mutation: CREATE_BUILDUP_ITEMS,
          variables: { input: items }
        }).then(() => {
          this.successMessage = 'Successfully created the build up!'
        }).catch(error => {
          this.incorrectPartMessage = error.message
        }).finally(() => {
          this.building = false
        })
      }).catch(() => {
        this.incorrectPartMessage = 'Could not create build'
      }).finally(() => {
        this.building = false
      })
    },

    removeInventoryItemFromPart (part) {
      const newParts = JSON.parse(JSON.stringify(this.parts))
      newParts.forEach(p => {
        if (p.id === part.id) {
          p.inventory = { id: '', ptID: '' }
          p.hold = []
        }
      })
      this.parts = newParts
    },

    refetchBOMs () {
      this.loadingBOMs = true
      this.$apollo.query({
        query: GET_BOMS_FOR_PARTS,
        variables: { input: {} }
      }).then(({ data: { parts_boms } }) => {
        this.boms = parts_boms
        this.loadingBOMs = false
      })
    },

    resetData () {
      this.boms = []
      this.parts = []
      this.inventory = []
      this.selectedBOM = undefined
      this.selectedPart = undefined
      this.outputBOM = undefined
      this.inventorySearch = ''
    },

    resetToBOMSelection () {
      this.resetData()
      this.refetchBOMs()
    },

    selectBOM (bom) {
      this.selectedBOM = bom
      this.showPartSelectionDialog = false
      this.loadingParts = true
      this.baseItem.part = bom.bom_for
      if (this.$route.params.itemId && this.$route.params.itemPtID) {
        this.baseItem.item.id = this.$route.params.itemId
        this.baseItem.item.ptID = this.$route.params.itemPtId
      }
      this.$apollo.query({
        query: GET_BOM_PARTS__FULL,
        variables: {
          input: {
            filters: [
              {
                key: 'bom__id',
                value: bom.id
              }
            ]
          }
        }
      }).then(({ data }) => {
        this.parts = data.parts_bom_parts
        this.parts.forEach(part => {
          part.ptID = ''
          part.inventory = { id: '', ptID: '' }
          part.hold = []
        })
        this.loadingParts = false
      })
    },

    selectBOMforPart (id) {
      this.$apollo.query({
        query: GET_BOMS_FOR_PARTS,
        variables: {
          input: {
            filters: [{ key: 'bom_for_id', value: id }]
          }
        }
      }).then(({ data: { parts_boms } }) => {
        if (parts_boms[0]) {
          this.selectBOM(parts_boms[0])
        }
      })
    },

    selectPart (part) {
      this.selectedPart = part // JSON.parse(JSON.stringify(part))
      this.dialog = true

      if (part.inventory) {
        const numberSelectedAlready = part.inventory.ptID ? part.inventory.ptID.split(',').length : 0
        const minValue = part.minimum_count - numberSelectedAlready
        this.min = minValue < 0 ? 0 : minValue
        const maxValue = part.maximum_count - numberSelectedAlready
        this.max = maxValue < 0 ? 0 : maxValue
      } else {
        this.min = 1
        this.max = 1
      }
      this.loadingInventory = true
      this.inventory = []
      this.$apollo.query({
        query: ITEMS_FOR_BUILDUP,
        variables: {
          pn: part.part.pn
        }
      }).then(({ data }) => {
        data.items.forEach((item, index) => {
          item.ptID = `${item.pt.transaction.id}-${item.pt.line_number}`
          item.serial_number = item.serial_number?.toUpperCase()
          // check to see if an item is already selected so that they cannot select the same item more than once
          if (this.selectedPtIDs.indexOf(item.ptID) > -1) {
            data.items.splice(index, 1)
          }
        })

        this.inventory = data.items
        this.loadingInventory = false
      })
    },

    selectInventory () {
      if (this.selected.length === 1 && this.selected[0].part.id === this.baseItem.part.id) {
        this.baseItem.item.id = this.selected[0].id
        this.baseItem.item.ptID = this.selected[0].ptID
      } else {
        const index = this.parts.findIndex(p => p.id === this.selectedPart.id)
        if (index > -1) {
          const newObj = this.parts[index]
          for (let i = 0; i < this.selected.length; i++) {
            const select = this.selected[i]
            if (i === 0) {
              newObj.inventory.id = select.id
              newObj.inventory.ptID = select.ptID
            } else {
              newObj.inventory.id += `, ${select.id}`
              newObj.inventory.ptID += `, ${select.ptID}`
            }
            newObj.hold.push({ id: select.id, ptID: select.ptID })
          }
          this.parts.splice(index, 1, newObj)
        }
      }
      this.selectedPart = undefined
      this.dialog = false
    },

    getShownQuantityToAdd (item) {
      const current = item.inventory.ptID ? item.inventory.ptID.split(',').length : 0
      const minValue = item.minimum_count - current
      const maxValue = item.maximum_count - current
      if (item.minimum_count === item.maximum_count) {
        return minValue < 0 ? 0 : minValue
      } else {
        return `${minValue < 0 ? 0 : minValue} - ${maxValue < 0 ? 0 : maxValue}`
      }
    },

    /**
     * Helper method to validate the PTs provided
     **/
    validatePTs () {
      this.incorrectPartMessage = ''
      this.notFoundMessage = ''
      this.ptErrors = ''
      let errorMessage = ''
      for (const ptID of this.pts.split(',')) {
        if (!/\d+-\d+/.test(ptID)) {
          errorMessage += `<span class="emphasis">${ptID}</span>`
        }
      }
      if (errorMessage) {
        this.ptErrors = `<b>Formatted incorrectly:</b> ${errorMessage}`
      } else {
        this.ptErrors = ''
      }
    },

    /**
     * Searches for inventory items based on the PTs provided if they are
     * all in the proper format and are not duplicates
     **/
    searchForItemPTs () {
      this.validatePTs()
      if (!this.ptErrors) {
        const isNotADuplicate = (array, string) => {
          return array.findIndex(e => e === string) === -1
        }
        const notFound = []
        const incorrectValues = []
        const searchArray = []
        const promises = []

        const matchUpFoundPtWithBomPart = ({ item, ptID }) => {
          const partID = item.part.id
          const index = this.parts.findIndex(p => p?.part?.id === partID)
          if (index > -1) {
            if (!this.parts[index].inventory.ptID) {
              this.parts[index].inventory.ptID = ptID
              this.parts[index].inventory.id = item?.id
            } else {
              this.parts[index].inventory.ptID += `, ${ptID}`
              this.parts[index].inventory.id += `, ${item?.id}`
            }
            this.parts[index].hold.push({ id: item?.id, ptiD: ptID })
            const newPart = this.parts[index]
            this.parts.splice(index, 1, newPart)
            return true
          } else {
            return false
          }
        }

        const checkMatchForBaseItem = async ({ item, ptID }) => {
          const partID = item.part.id
          const itemID = item.id
          const response = await this.$apollo.query({
            query: GET_BUILD_UPS__GRID,
            variables: { input: { filters: [{ key: 'system_id', value: itemID }] } }
          })
          const buildUps = response.data.build_up_build_ups
          const isUnique = buildUps.length === 0
          if (partID === this.baseItem.part.id && isUnique) {
            this.baseItem.item.ptID = ptID
            this.baseItem.item.id = item.id
            return true
          } else {
            return false
          }
        }

        /* Prep Data and check for errors */
        for (const ptID of this.pts.split(',')) {
          // loop through and make sure no duplicates, if there are ignore them
          if (isNotADuplicate(searchArray, ptID)) {
            searchArray.push(ptID)
          }
        }

        this.searchingWithPTs = true
        for (const ptID of searchArray) {
          const split = ptID.split('-')
          const promise = this.$apollo.query({
            query: ITEM_FROM_PT_ID,
            variables: { pt: split[0], line: split[1] }
          }).then(({ data: { item } }) => {
            return { ptID: ptID, item: item }
          }).catch(() => {
            notFound.push(ptID)
            return false
          })
          promises.push(promise)
        }

        Promise.all(promises).then(async (values) => {
          // actual data is at values[i].data.item
          const successfulValues = values.filter(v => v)

          // handle success
          for (const value of successfulValues) {
            const validItem = matchUpFoundPtWithBomPart(value)
            if (!validItem) {
              const matchesBaseSystem = await checkMatchForBaseItem(value)
              !matchesBaseSystem && notFound.push(value.ptID)
            }
          }

          // handle failures -- notFound && incorrectParts
          let notFoundMessage = ''
          for (const ptID of notFound) {
            notFoundMessage += `<span class="emphasis">${ptID}</span>`
          }
          if (notFoundMessage) this.notFoundMessage = `<b>DNE or already marked for a build up:</b> ${notFoundMessage}`
          let incorrectPartMessage = ''
          for (const value of incorrectValues) {
            incorrectPartMessage = `<span class="emphasis">${value.ptID}: ${value.item.part.pn}</span>`
          }
          if (incorrectPartMessage) this.incorrectPartMessage = `<b>These PT #'s are not on the BOM:</b> ${incorrectPartMessage}`
          this.searchingWithPTs = false
          this.pts = ''
        })
      }
    },

    /**
     * Method to do the multi-selection
     * @param {Object} props the row that was shift-clicked
     **/
    multiSelect (props) {
      if (this.selected.length >= 2) {
        const items = this.$refs.inventoryTable.filteredItems
        let index = items.findIndex(i => i.id === this.selected[this.selected.length - 2].id)
        // if multi-selecting DOWN the table
        if (index > props.index && this.selected.length < this.max) {
          while (index !== props.index) {
            const newIndex = this.selected.findIndex(s => s.id === items[index].id)
            const alreadyExists = newIndex > -1
            !alreadyExists && this.selected.push(items[index])
            index--
          }
        } else if (index < props.index && this.selected.length < this.max) {
          // if multi-selecting UP the table
          while (index !== props.index) {
            const newIndex = this.selected.findIndex(s => s.id === items[index].id)
            const alreadyExists = newIndex > -1
            !alreadyExists && this.selected.push(items[index])
            index++
          }
        }
      }
    },

    /**
     * Used to handle when pressing shift for multi-select in the inventory data-table
     * @param e
     */
    keyupHandler (e) {
      if (e.key === 'Shift') {
        this.shiftIsPressed = false
      }
    },

    /**
     * Used to handle when pressing shift for multi-select in the inventory data-table
     * @param e
     */
    keydownHandler (e) {
      if (e.key === 'Shift') {
        this.shiftIsPressed = true
      }
    }
  },
  beforeMount () {
    this.resetToBOMSelection()
    if (this.$route.params.id) {
      this.selectBOMforPart(this.$route.params.id)
      this.baseItem.item.ptID = this.$route.params.itemPtID ?? ''
      this.baseItem.item.id = this.$route.params.itemID ?? 0
    } else {
      // if they are coming to this component from clicking the new button we want them
      // to select a part to build up from
      this.showPartSelectionDialog = true
    }
  },
  mounted () {
    window.addEventListener('keyup', this.keyupHandler)
    window.addEventListener('keydown', this.keydownHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyupHandler)
    window.removeEventListener('keydown', this.keydownHandler)
  }
}

</script>

<style>

.my-monospaced {
  font-family: "Roboto Mono", sans-serif;
}

</style>
