<template>
  <v-icon>{{ getPartTypeIcon(name) }}</v-icon>
</template>

<script>
export default {
  name: 'PartIcon',
  props: {
    name: String
  },
  methods: {
    getPartTypeIcon (type) {
      switch (type.toLowerCase()) {
        case 'accesspoint':
          return 'fas fa-wifi'
        case 'battery':
          return 'fas fa-battery-bolt'
        case 'bezel':
          return 'fas fa-border-outer'
        case 'bracket':
          return 'fas fa-brackets'
        case 'cpu':
          return 'fas fa-microchip'
        case 'cable':
          return 'fas fa-plug'
        case 'cache':
          return 'fas fa-sd-card'
        case 'diskdrive':
          return 'fas fa-disc-drive'
        case 'expansioncard':
          return 'fas fa-stream'
        case 'fan':
          return 'fas fa-fan'
        case 'gpu':
          return 'fad fa-microchip'
        case 'heatsink':
          return 'fas fa-heat'
        case 'memory':
          return 'fas fa-memory'
        case 'motherboard':
          return 'fas fa-border-none'
        case 'phone':
          return 'fas fa-phone-office'
        case 'powersupply':
          return 'fas fa-power-off'
        case 'router':
          return 'fas fa-router'
        case 'server':
          return 'fas fa-server'
        case 'storage':
          return 'fas fa-hdd'
        case 'switch':
          return 'fas fa-network-wired'
        default:
          return ''
      }
    }
  }
}

</script>

<style scoped>

</style>
